@import "../../../styles/theme.scss";

.modal {
  padding: 0 !important;
  -webkit-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.15);
}

.modalContainer {
  display: flex;
  flex-direction: row;
}

.contentContainer {
  width: 350px;

  &:nth-child(2) {
    padding: 24px;
    border-left: 1px solid #e8e8e8;
    display: flex;

    form {
      width: 100%;
      align-self: center;
    }
  }
}

.infoContainer {
  padding: 18px 24px;

  &:first-child {
    border-bottom: 1px solid #e8e8e8;
  }

  .img {
    height: 25px;
    margin-left: 8px;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 7px;
  }

  .text {
    font-size: 14px;
    color: black;
    font-weight: 400;
    margin-bottom: 7px;
  }

  .bold {
    font-weight: bold;
  }
}

.payButton {
  z-index: 1;
  width: 100%;
  height: 28px !important;
  margin-block-start: 12px;
  font-weight: bold !important;
  color: $accent-color !important;
  border-color: $accent-color !important;
}

.payButton:hover {
  opacity: 0.8;
}

.payButton:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.cardNumberContainer {
  position: relative;
}

.cardVendorContainer {
  position: absolute;
  right: 0;
  display: inline;

  .cardVendor {
    height: 15px;
    margin-inline-end: 4px;
  }
}

.inputContainer {
  display: inline-block;
  &:nth-child(2) {
    width: 67%;
    margin-inline-end: 3%;
  }

  &:nth-child(3) {
    width: 30%;
  }
}

.smallText {
  font-size: 12px;
  color: #4d4d4d;
  text-align: center;
  margin-block-start: 16px;
}

.emailText {
  font-size: 16px;
  color: $primary-color;
  text-align: center;
  margin-block-start: -14px;
}

.logoContainer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 200px;
  width: 200px;
  overflow: hidden;
}

.logo {
  opacity: 8%;
  height: 190px;
  transform: rotate(-33deg);
  position: absolute;
  right: -50px;
  bottom: -80px;
}

@media (max-width: 768px) {
  .modalContainer {
    flex-direction: column;
  }

  .contentContainer {
    width: auto;
    &:nth-child(2) {
      padding: 16px;
    }
  }

  .infoContainer {
    padding: 16px;

    &:nth-child(2) {
      border-bottom: 1px solid #e8e8e8;
    }

    .text {
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 1px;
    }
  }

  .logoContainer {
    height: 100px;
    width: 100px;
  }

  .logo {
    opacity: 13%;
    height: 90px;
    right: -20px;
    bottom: -20px;
  }
}
