.card {
  border-radius: 8px !important;
  height: 100%;
  position: relative;

  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15),
    0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15),
    0 8px 8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);

  .fieldContainer > p:first-child {
    font-weight: bold;
  }

  .buttonContainer {
    position: static !important;
    & > button {
      position: absolute;
      right: 24px;
      top: 24px;
    }

    & > button:hover,
    & > button:focus {
      color: red;
      border-color: red;
    }
  }
}

@media (max-width: 768px) {
  .card > div {
    display: flex;
    flex-direction: column;
  }

  .fieldContainer {
    text-align: left !important;
  }

  .fieldContainer > p {
    display: inline-block;
    margin-block-end: 8px;

    &:first-child {
      margin-inline-end: 8px;
    }
  }

  .buttonContainer {
    position: static !important;

    & > button {
      position: absolute;
      right: 24px;
      top: 24px;
    }
  }
}
