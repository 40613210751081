.header {
  & > li:focus {
    color: #1890ff;
    border-bottom: 2px solid #1890ff;
  }
}

.active {
  color: #1890ff !important;
  border-bottom: 2px solid #1890ff !important;
}
