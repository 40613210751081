@import "../../../styles/variables.scss";

.modal > div {
  border-radius: 8px;
  z-index: -2;

}

.title {
  font-weight: bold;
  font-size: calc(18px + (28 - 18) * ((100vw - 300px) / (1920 - 320)));
  line-height: 1.3em;
}

.modalsmalltext {
  font-size: 12px;
}

.highlight {
  font-weight: bold;
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1920 - 320)));
}

.svg {
  position: absolute;
  right: -30px;
  bottom: -41px;
  width: 300px;
  z-index: -1;
}
.buttonsContainer {
  margin-block-start: 24px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;

  .googleButton {
    border-color: $secondary-color !important;
    background-color: $secondary-color !important;
    width: 100%;
    color: white;
    margin-block-end: 8px;
    font-weight: 500 !important;
    opacity: 1;
  }

  .guestCheckout {
    width: 100%;
    font-weight: 500;
  }

  .facebookButton {
    background-color: $primary-color !important;
    width: 100%;
    border-color: $primary-color !important;
    font-weight: 500 !important;
    opacity: 1;
  }
  .facebookButton:hover,
  .googleButton:hover {
    filter: contrast(0.8);
  }
}

@media (min-height: 569px) and (max-height: 812px) {
  .modalbodytext {
    font-size: 14px;
    line-height: 1.5em;
  }
  .modalsmalltext {
    line-height: 1.5em;
  }
  .buttonsContainer {
    min-height: 200px !important;
  }
}

@media (max-height: 568px) {
  .modalbodytext {
    font-size: 14px;
    line-height: 1.2em;
  }
  .modalsmalltext {
    line-height: 1.5em;
  }
  .modal {
    top: 0 !important;
  }
  .svg {
    display: none;
  }
  .buttonsContainer {
    min-height: 0px !important;
  }
}
