@import "./styles/variables.scss";
@import "~antd/dist/antd.css";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "./styles/theme.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  height: auto;
  width: auto;
}
