.nav {
  background-color: transparent !important;
  border-bottom: 0px !important;
  color: white !important;
  font-weight: bold;
  float: right;

  & > li {
    color: white !important;

    & > h2 {
      margin-block-end: 0;
    }
  }

  & > li:hover,
  & > li:focus {
    color: white !important;
    border-color: white !important;
  }
}

@media (max-width: 1200px) {
  .nav {
    float: none;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
}
