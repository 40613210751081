.header {
  border-radius: 24px;
  width: calc(100% - 348px);
  margin: 24px;
  margin-bottom: calc(10px + (24 - 10) * ((100vh - 568px) / (900 - 568)));
  display: flex;
  align-items: center;

  .logo {
    width: calc(30px + (60 - 30) * ((100vh - 568px) / (900 - 568)));
    height: calc(30px + (60 - 30) * ((100vh - 568px) / (900 - 568)));
  }

  .infoContainer {
    margin-inline-start: calc(5px + (16 - 5) * ((100vh - 568px) / (900 - 568)));

    & > h1 {
      font-weight: bold;
      margin-bottom: -2px;
      font-size: calc(16px + (35 - 16) * ((100vh - 568px) / (900 - 568)));
    }
  }
}

@media (max-width: 768px) {
  .header {
    width: calc(100% - 48px);
  }
} 

@media (max-height: 580px) 
{
  .header {
    margin: 10px;
  }
  .logo {
    display: none;
  }
  .infoContainer {
    display: none;
  } 
}