.container {
  min-height: 100vh;
  background-image: url("/images/bksample.webp"), url("/images/bksample.jp2");
  background-size: cover;
  display: flex;
  align-items: center;

  .error {
    color: red;
    font-weight: bold;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 0 16px;
  }
}
