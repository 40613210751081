.content {
  width: 600px;
  padding: 30px 10px 30px;
}
.title {
  font-size: 26px;
  color: black;
  font-weight: bold;
  margin-bottom: 0px;
}

.updateText {
  margin-bottom: 15px;
}

.heading {
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.text {
  font-size: 16px;
  color: black;
}


@media (max-width: 850px) {
  .content {
    width: 100%;
    padding: 35px;
  }
}

@media (max-width: 360px) {
  .content {
    padding: 30px 10px 30px 10px;
  }
}