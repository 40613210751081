@import "../../../styles/variables.scss";

.bookButton {
  margin-top: -30px;
  right: 24px;
  bottom: 0;
  position: absolute !important;
  height: calc(35px + (40 - 35) * ((100vw - 320px) / (500 - 320))) !important;
  padding-left: calc(
    2px + (10 - 2) * ((100vw - 320px) / (500 - 320))
  ) !important;
  padding-right: calc(
    2px + (10 - 2) * ((100vw - 320px) / (500 - 320))
  ) !important;
  font-size: calc(
    14px + (16 - 14) * ((100vw - 320px) / (500 - 320))
  ) !important;

  background-color: $accent-color !important;
  color: white !important;
}

.demoGif {
  width: 100%;
}

@media (min-width: 769px) {
  .bookButton {
    display: none !important;
  }
}
