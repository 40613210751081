@import "../../../styles/variables.scss";

.container {
  background-image: url("/images/bksample.webp"), url("/images/bksample.jp2");
  background-size: cover;
  overflow: auto;
  min-height: 100vh;

  & h1,
  & h2,
  & h3 {
    color: white;
  }

  .headerContainer {
    display: flex;
    align-items: center;
    margin-block-end: 96px;

    .navContainer {
      margin-block-start: 8px;
    }
  }

  .clubInfo {
    padding: 24px 0;
    display: flex;
    align-items: center;

    .logo {
      width: calc(65px + (150 - 65) * ((100vw - 320px) / (1920 - 320)));
      height: calc(65px + (150 - 65) * ((100vw - 320px) / (1920 - 320)));
    }

    .nameContainer {
      margin-inline-start: 16px;
      & > h1 {
        margin-block-end: -4px;
        font-weight: bold;
        font-size: calc(24px + (38 - 24) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
      }
    }
  }

  .infoContainer {
    & > h1,
    & > h3 {
      text-transform: uppercase;
      margin-block-end: 0px;
      line-height: 1.3em;
      position: relative;
    }

    & > h3 > span {
      position: relative;
    }

    & > h3 > span::before {
      content: "";
      width: 100%;
      border-top: 6px solid $primary-color;
      position: absolute;
      top: -8px;
    }

    .button {
      font-size: 24px;
      padding: 4px 16px;
      margin-block-start: 8px;
      border-radius: 10px;
    }
  }

  .socialMedia {
    display: flex;
    flex-direction: column;

    .icon {
      color: white;
      font-size: 22px;
    }

    .description {
      margin-block-start: 16px;
    }

    .fblink {
      font-size: 22px;
      display: inline-block;
      font-weight: 300;
    }
  }
}

@media (min-width: 769px) {
  .container {
    height: 100vh !important;
  }
}

@media (max-width: 768px) {
  .clubInfo {
    margin-inline-start: 0 !important;
  }

  .headerContainer {
    flex-direction: column;
    align-items: flex-start;

    .navContainer {
      margin-block-start: -24px !important;
    }

    .logo {
      //width: 75px !important;
      //height: 75px !important;
    }
  }

  .button {
    font-size: calc(
      19px + (24 - 19) * ((100vw - 320px) / (768 - 320))
    ) !important;
  }

  .socialMedia {
    margin-block-start: 32px;
    flex-direction: column-reverse !important;
    font-size: calc(9px + (16 - 9) * ((100vw - 320px) / (768 - 320)));
    line-height: calc(18px + (28 - 18) * ((100vw - 320px) / (768 - 320)));
  }
}

@media (max-width: 576px) {
  .container {
    & h3 {
      font-size: 18px;
    }
    & h1 {
      font-size: 22px;
    }
    .description {
      font-size: 18px;
    }
  }
}

@media (max-width: 320px) {
  .description {
    font-size: 16px;
  }

  .headerContainer {
    margin-block-end: 40px !important;
  }

  .fb {
    display: none;
  }
}

@media (max-width: 768px) {
  .container {
    height: auto;
  }
}
