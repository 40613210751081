.card {
  border-radius: 8px !important;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15),
    0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15),
    0 8px 8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);

  & p {
    color: black;
    font-weight: bold;

    & > span {
      //   color: darkgrey;
      font-weight: 400;
    }
  }
}

.editButton {
  position: absolute !important;
  right: 24px;
  top: 24px;
}
