@import "../../../styles/variables.scss";

.group {
  z-index: 1;
  & > button {
    font-size: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    padding-top: calc(2px + (6 - 2) * ((100vw - 640px) / (1920 - 640)));
    padding-bottom: calc(0px + (6 - 0) * ((100vw - 640px) / (1920 - 640)));
    padding-right: calc(3px + (16 - 3) * ((100vw - 320px) / (1080 - 320)));
    padding-left: calc(3px + (16 - 3) * ((100vw - 320px) / (1080 - 320)));
    font-weight: bold;
    text-transform: uppercase;

    & > p {
      margin-block-end: -12px;
    }

    & > span {
      font-size: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 400;
    }
  }
  & > button:hover,
  & > button:focus {
    color: $primary-color;
    border-color: $primary-color;
  }

  .active {
    color: $primary-color;
    border-color: $primary-color;
    z-index: 2;
  }
}

@media (max-width: 768px) {
  .group {
    z-index: 1;
    & > button {
      font-size: calc(12px + (30 - 12) * ((100vw - 320px) / (768 - 320)));
      padding-top: calc(2px + (10 - 2) * ((100vw - 320px) / (768 - 320)));
      padding-right: calc(5px + (26 - 5) * ((100vw - 320px) / (768 - 320)));
      padding-left: calc(5px + (26 - 5) * ((100vw - 320px) / (768 - 320)));
    }
  }
}
