@import "../../styles/variables.scss";

.header {
  background-color: $primary-color;
  display: flex;
  align-items: center;
}

.logo {
  width: 55px;
  height: 55px;
  margin: 15px 10px 15px 15px ;
}

.title {
  color: white;
  font-weight: bold;
  font-size: 38px;
  margin: 0;
}

@media (max-width: 400px) {
  .title {
    font-size: 28px;
  } 
}
