@import "./variables.scss";

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:disabled,
.ant-btn-primary:disabled:hover {
  height: auto;
  font-weight: bold;
  background-color: transparent;
  color: white;
  border: solid 2px white;
  padding: 6px 8px;
  opacity: 0.8;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  opacity: 1;
}

//menu
.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: solid 2px white;
}

//scheduler-fullcalendar
.fc-time-grid .fc-slats td {
  height: calc(21px + (63 - 21) * 0.8 * ((100vh - 568px) / (1080 - 568)));
}

.fc-view-container {
  font-size: calc(12px + (20 - 12) * ((100vw - 768px) / (1920 - 768)));
}

.fc-ltr .fc-axis {
  font-weight: bold;
}

@media (max-height: 1366px) and (max-width: 1150px) {
  .fc-time-grid .fc-slats td {
    height: calc(18px + (50 - 18) * 0.65 * ((100vh - 568px) / (1080 - 568)));
  }

  .fc-view-container {
    font-size: 14px;
  }

  .fc-time-grid-event .fc-time {
    font-size: 13px;
  }

  .fc-ltr .fc-axis {
    font-size: 13px;
    font-weight: bold;
  }
}

//special case for phones so that the only current date displayed is larger
@media (max-width: 768px) {
  .fc-view-container {
    font-size: calc(16px + (18 - 16) * 0.65 * ((100vh - 320px) / (500 - 320)));
  }
}

.fc-button-primary,
.fc-button-primary:not(:disabled):active,
.fc-button-primary:focus {
  background-color: $primary-color;
  border-color: white;
  filter: contrast(1);
}

.fc-button-primary:hover,
.fc-button-primary:active {
  background-color: $primary-color;
  border-color: white;
  filter: contrast(0.8);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus {
  border-color: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.fc-button-primary:disabled {
  border-color: white;
}

.fc-toolbar {
  display: flex;
  justify-content: flex-end;
}

.fc-toolbar h2 {
  font-size: calc(11px + (30 - 11) * ((100vw - 320px) / (1920 - 320)));
  margin-right: 15px;
}

.fc-toolbar > div:first-child {
  margin-inline-end: 0.75em;
}

// don't display the bottom prev and next buttons when viewing on desktop
@media (min-width: 769px) {
  .fc-left {
    display: none;
  }
}

//scheduler date
@media (max-width: 768px) {
  .fc-view-container {
    // max-height: 50vh;
    // overflow: scroll;
  }

  .fc-toolbar.fc-header-toolbar {
    margin: 16px 0;
  }

  .fc-toolbar {
    justify-content: flex-start;
  }

  .fc-right {
    display: none;
  }

  .fc-center {
    display: none;
  }

  .fc-button,
  .fc-button-group {
    font-size: 12px;
    height: calc(35px + (40 - 35) * ((100vw - 320px) / (500 - 320)));
  }

  .fc-button-group {
    margin-inline-end: 8px;
  }
}

//landing page text highlight
.highlight::before {
  content: "";
  width: 30%;
  border-top: 6px solid $primary-color;
  position: absolute;
  top: -8px;
}

.fc-event-container {
  width: 100%;
  margin: 0 !important;
}
