.container {
  width: 100%;
  margin-block-end: 32px;
}

.datePicker {
  width: 100%;
}

@media (max-width: 576px) {
  .container {
    display: flex !important;
    flex-direction: column;

    & > div {
      margin-block-end: 16px;
    }
  }
}
