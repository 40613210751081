.container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: auto;
}

.button {
  margin: 0px 2px 5px 2px;
}
.buttonText {
  font-size: 16px;
  padding-top: 3px;
}

@media (max-width: 850px) {
  .container {
    flex-direction: row;
    padding-bottom: 0px;
    
  }
}

@media (max-width: 580px) {
  .container {
    width: 100%;
    flex-direction: column; 
  }
}

@media (max-width: 360px) {
  .buttonText {
    font-size: 14px;
    padding-top: 3px;
  }

  .container {
    padding: 30px 5px 30px 5px;
  }
}