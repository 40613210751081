.drawer {
  & div:first-child {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  & > div:first-child > div:first-child {
    //height: calc(100vh - 48px);
    //height: calc(100vh*0.82);
    height: calc(var(--vh, 1vh) * 94);
    background-color: #303c6c;
    margin: 24px 0;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
  }

  & h2,
  & h3,
  & h1 {
    color: white;
  }

  & h2,
  & h3 {
    font-weight: bold;
  }

  .title {
    text-transform: uppercase;
    font-size: 31px;
  }

  .bookingsContainer {
    color: white;
    margin-block-start: calc(5px + (24 - 5) * ((100vh - 568px) / (812 - 568)));
    margin-block-end: calc(5px + (24 - 5) * ((100vh - 568px) / (812 - 568)));

    .booking {
      margin-block-end: calc(1px + (8 - 1) * ((100vh - 568px) / (812 - 568)));
    }

    .date {
      margin-inline-end: 16px;
      font-size: calc(16px + (18 - 16) * ((100vh - 568px) / (812 - 568)));
      font-weight: bold;
      text-transform: capitalize;
    }

    .hour {
      font-size: calc(16px + (18 - 16) * ((100vh - 568px) / (812 - 568)));
    }
  }

  .priceContainer {
    color: white;
    position: relative;
    font-size: calc(16px + (18 - 16) * ((100vh - 568px) / (812 - 568)));
    font-weight: bold;
    & > span:nth-child(2) {
      float: right;
    }
  }

  .priceContainer:last-child {
    margin-block-end: 24px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: -4px;
      right: 0;
      border-bottom: 2px solid white;
    }
  }
  .totalContainer {
    margin-block-start: -16px;

    & > h2 {
      display: inline-block;
    }
    & > h2:last-child {
      float: right;
    }
  }

  .logoContainer {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: calc(
      150px + (200 - 150) * ((100vh - 568px) / (1200 - 568))
    ); //200px;
    width: calc(
      150px + (200 - 150) * ((100vh - 568px) / (1200 - 568))
    ); //200px;
    overflow: hidden;

    .logo {
      width: calc(
        187.5px + (250 - 187.5) * ((100vh - 568px) / (1200 - 568))
      ); //250px;
      height: calc(
        187.5px + (250 - 187.5) * ((100vh - 568px) / (1200 - 568))
      ); //250px;
      transform: rotate(-35deg);
      opacity: 0.15;
      bottom: -72px;
      right: -72px;
    }
  }

  .bookingButton {
    margin-block-start: calc(3px + (24 - 3) * ((100vh - 568px) / (812 - 568)));
    font-size: calc(20px + (22 - 20) * ((100vh - 568px) / (1080 - 568)));
  }
  svg {
    color: white;
  }
}

.adminView,
.customerView {
  position: relative;
  z-index: 2;
}

.notes {
  margin-block-start: 24px !important;
  background-color: #3b4a87 !important;
  color: white !important;
  border: 1px solid #3b4a87 !important;
}
