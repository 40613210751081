@import "../../../styles/variables.scss";

.container {
  background-color: $primary-color;
  padding: 24px 0;
  position: relative;
  min-height: 182px;
  position: relative;

  & h2 {
    color: white;
  }

  // .tocTitleText {
  //   margin-block-start: 24px;
  // }

  .infoContainer {
    display: flex;
    align-items: center;
    color: $secondary-color;

    & > h3 {
      margin-block-end: 0;
      margin-inline-start: 8px;
      color: white; 

      & > a{
        color: white; 
        text-decoration: underline;
      }
    }

    
  }

  .logo {
    width: 150px;
    height: 150px;
    position: absolute;
    right: 8px;
    opacity: 0.3;
  }
}

@media (max-width: 576px) {
  .logo {
    width: 75px !important;
    height: 75px !important;
    bottom: 16px;
  }
}

@media (max-width: 768px) {
  .tocTitleText {
    margin-block-start: 24px;
  }
}
