.label {
  color: #5c6d85;
  font-weight: 400;
  letter-spacing: 0.025em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.StripeElement {
  display: block;
  margin: 4px 0 16px 0;
  /* max-width: 500px; */
  padding: 4px 14px;
  font-size: 14px;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.ElementsApp input {
  font-size: 16px;
}
