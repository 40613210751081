.schedulerContainer {
  margin: -32px 24px 0 24px;
}

.event {
  font-size: calc(
    10px + (16 - 10) * ((100vw - 768px) / (1920 - 768))
  ) !important;
  text-align: center;
  font-size: medium;
  font-weight: 600;
  margin-left: 1px;
  cursor: pointer;
  width: calc(100% - 4px);
  left: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.disabledHours {
  width: 100%;
  border: 0 !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
  background-color: #ddddddc2 !important;
  div {
    display: none;
  }
}

@media (max-width: 1150px) {
  //changed from 768 so that there is space for two more buttons on vertical ipad viewport dimensions
  .schedulerContainer {
    margin-top: 10px;
  }

  .event {
    font-size: 0.85em !important;
  }
}

.confirmationModal button:last-child {
  // margin-left: 30px;
  // line-height: 1.5;
  // position: relative;
  // display: inline-block;
  // font-weight: 400;
  // white-space: nowrap;
  // text-align: center;
  // background-image: none;
  // border: 1px solid transparent;

  // cursor: pointer;
  // touch-action: manipulation;
  // height: 32px;
  // padding: 0 15px;
  // font-size: 14px;
  // border-radius: 4px;
  // color: rgba(0, 0, 0, 0.65);
  // background-color: #fff;
  // border-color: #d9d9d9;
}
